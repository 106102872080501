import React, { Component } from 'react'
import { BrowserRouter, Route, Switch, } from 'react-router-dom'
import './scss/style.scss'
import  './assets/css/common.css';
import Login from './login/login'
import SignUp from './signup/Signup'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { QueryClient, QueryClientProvider } from 'react-query';
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
//const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const GridExample = React.lazy(()=> import('./location/agTest'))
const AddDeviceMobile = React.lazy(()=>import ('./addDevice/AddDeviceMobile'));
const DashboardMobile = React.lazy(()=>import('./dashboard/DashboardMobile'));
const LocationMobile = React.lazy(()=>import('./location/LocationMobile'));
// const DonutChartMobile = React.lazy(()=>import('./visualReport/components/donutChartMobile'));
const VisualReportMobile = React.lazy(()=>import('./visualReport/VisualReportMobile'));
const ResetPassword =  React.lazy(()=>import('./resetpassword/ResetPassword'))
const DashboardMobileLanding = React.lazy(()=>import('./dashboard/DashboardMobileLanding'))
const SmplrSpace = React.lazy(()=>import('./smplrspace/index'));
const DeviceErrorLogs = React.lazy(()=>import('./deviceErrorLogs/deviceErrorLogs'));
const IRRemote = React.lazy(()=>import('./iRRemoteMobile/IRRemote'));
const ConfigurationMobile = React.lazy(()=>import('./configuration/ConfigurationMobile'));
const AlertMobile = React.lazy(()=>import('./alert/AlertMobile'));
const DeviceErrorLogsMobile = React.lazy(()=>import('./deviceErrorLogs/deviceErrorLogsMobile'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0, // Prevents caching
      staleTime: 0, // Always refetch
      refetchOnMount: "always",
      refetchOnWindowFocus: "always",
    },
  },
});

class App extends Component {
  componentDidMount() {
    // **Unregister service workers**
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => registration.unregister());
      });
    }
  }

  render() {
    return (
       <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            <Route exact path="/smplr-space" name="Login Page" render={(props) => <SmplrSpace {...props} />} />
            

            <Route exact path="/dashboard-landing/:userSession/:org_id" name="Dashboard" render={(props) => <DashboardMobileLanding {...props} />} />
            <Route exact path="/add-device/:userSession/:org_id" name="Add Device" render={(props) => <AddDeviceMobile {...props} />} />
            <Route exact path="/dashboard/:userSession/:org_id/:location_id/:index/:view/:heightChart" name="Dashboard" render={(props) => <DashboardMobile {...props} />} />
            <Route exact path="/location/:userSession/:org_id/:location_id/:index/:view/:heightChart" name="location" render={(props) => <LocationMobile {...props} />} />
            {/* <Route exact path="/visual-report-index/:userSession/:org_id/:index/:view" name="VIsual Report" render={(props) => <DonutChartMobile {...props} />} /> */}
            <Route exact path="/visual-report-mobile/:userSession/:org_id/:index/:view/:locations?/:heightChart" name="Visual Report" render={(props) => <VisualReportMobile {...props} />} />
            <Route exact path="/device-configuration/:userSession/:org_id" name="Device Configuration" render={(props) => <ConfigurationMobile {...props} />} />
            <Route 
                  exact 
                  path="/visual-report-mobile/:userSession/:org_id/:index/:view//:heightChart" 
                  render={(props) => {
                    const { userSession, org_id, index, view, heightChart } = props.match.params;
                    return (
                      <Redirect 
                        to={`/visual-report-mobile/${userSession}/${org_id}/${index}/${view}/none/${heightChart}`} 
                      />
                    );
                  }} 
                />
            <Route exact path="/signup" name="Signup Page" render={(props) => <SignUp {...props} />} />
            <Route exact path="/ir-remote-mobile/:selectedDevice/:userSession/:org_id/" name="IR Remote" render={(props) => <IRRemote {...props} />} />
            <Route exact path="/alert-mobile/:userSession/:org_id/" name="Alert" render={(props) => <AlertMobile {...props} />} />
            <Route exact path="/reset-password" name="Signup Page" render={(props) => <ResetPassword {...props} />} />
            {/* <Route exact path="/error-logs" name="Device Error Logs" render={(props) => <DeviceErrorLogs {...props} />} /> */}
            <Route exact path="/error-logs/:device_serial" name="Device Error Logs" render={(props) => <DeviceErrorLogs {...props} />} />
            <Route exact path="/error-logs-mobile/:userSession/:org_id/" name="Device Error Logs" render={(props) => <DeviceErrorLogsMobile {...props} />} />
            <Route exact path="/reset-password" name="Signup Page" render={(props) => <ResetPassword {...props} />} />

            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
             <Route
          exact
          path="/docx"
          render={() => {
            window.location.href = "/docx.html"; // Redirect to static HTML
            return null;
          }}
        />
            <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
            <Route path="/grid" name="AG Grid" render={(props) => <GridExample {...props} /> } />
           </Switch>
        </React.Suspense>
      </BrowserRouter>
      </QueryClientProvider>
    )
  }
}

export default App
